import {http} from '@/plugins/axios/axios'

const getList = (params) => {
  return http.get('/v1/jackpots', {
    params
  })
}

const createReward = (payload) => {
  return http.post('/v1/jackpots/reward', payload)
}

const getUsersByJackpot = (id, params) => {
  return http.get(`/v1/jackpots/${id}/eligible-users`, {
    params
  })
}

export default {
  getList,
  createReward,
  getUsersByJackpot,
}
