<template>
  <div class='jackpot-page'>
    <div class='actions d-flex justify-end align-center mb-20'>
      <a-date-picker class='mr-15 w-200' placeholder='--Select date--' format="DD-MM-YYYY" v-model='query.time'
                     @change='onChangeFilter' :allowClear="false"/>
      <a-button @click="onFetchData" class="mr-15">
        <a-icon type='sync'/>
        Refresh
      </a-button>
    </div>
    <a-table :columns='columns' :data-source='listJackpots' row-key='id' :loading='loading' :scroll='{ x: 900}'
             :pagination="{
      current: query.currentPage,
      pageSize: query.pageSize,
      total: total
             }" @change="onChangeTable">
      <template slot='stt' slot-scope='item, data, index'> {{
          (query.currentPage - 1) * query.pageSize + index + 1
        }}
      </template>
      <template slot='benefit' slot-scope='item'>
        <span>{{ item.benefit.toFixed(2) }}</span>
      </template>
      <template slot='status' slot-scope='item'>
        <a-tag color='green' v-if='item.status === 1'>Paid</a-tag>
        <a-tag color='red' v-if='item.status === 0'>Pending</a-tag>
      </template>
      <template slot='startTime' slot-scope='item'>
        <span>{{ item.startTime ? convertGmt9(item.startTime, 'DD-MM-YYYY HH:mm:ss', 'DD-MM-YYYY HH:mm:ss') : '' }}</span>
      </template>
      <template slot='endTime' slot-scope='item'>
        <span>{{ item.endTime ? convertGmt9(item.endTime, 'DD-MM-YYYY HH:mm:ss', 'DD-MM-YYYY HH:mm:ss') : '' }}</span>
      </template>
      <template slot='action' slot-scope='item'>
        <a-tooltip>
          <template slot="title">
            Reward
          </template>
          <a-button :disabled="item.status !== 1 || moment().isAfter(moment(item.endTime, 'DD-MM-YYYY HH:mm:ss'))" type='link' size='small' @click="onShowModalReward(item)">
            <a-icon v-if="item.status === 1 || moment().isAfter(moment(item.endTime, 'DD-MM-YYYY HH:mm:ss'))" type="gift" />
            <a-icon v-else type="gift" theme='twoTone' two-tone-color="#52c41a"/>
          </a-button>
        </a-tooltip>
      </template>
    </a-table>

    <a-modal v-model="visible" title="Reward" :maskClosable="false" :width="700" @cancel="onCloseModalReward">
      <a-row :gutter="[16, 0]">
        <a-col :xs="24" :md="12">
          <a-form-item label="Amount" required>
            <a-input-number placeholder="0" :min="0" style="width: 100%;" v-model="rewardAmount"/>
          </a-form-item>
        </a-col>
        <a-col :xs="24" :md="12">
          <a-form-item label="Rank" required>
            <a-select style="width: 100%" v-model="rewardRank" :options="rankOptions" @change="onChangeRank" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item label="Wallet address" style="margin-bottom: 0;">
        <a-table :columns='columnsUsers' :data-source='listUsers' row-key='id' :loading='loadingUsers'
                 :pagination="{
                    current: queryUsers.page,
                    pageSize: queryUsers.size,
                    total: totalUsers
                 }"
                 @change="onChangeTableUsers"
        >
          <template slot='walletAddress' slot-scope='item'>
            <span>{{ item.walletAddress }}</span>
          </template>
          <template slot='status' slot-scope='item'>
            <a-tag color='green' v-if='item.status === 1'>Active</a-tag>
            <a-tag color='red' v-if='item.status === 0'>Inactive</a-tag>
          </template>
          <template slot='action' slot-scope='item'>
            <a-radio :checked="item.walletAddress === selectedWalletAddress" :value="item.walletAddress"
                     @change="onRadioChange"/>
          </template>
        </a-table>
      </a-form-item>
      <template slot="footer">
        <a-button key="back" @click="onCloseModalReward">
          Cancel
        </a-button>
        <a-button :disabled="!selectedWalletAddress || !rewardAmount || rewardAmount < 0" type="primary"
                  :loading="loadingSubmitReward" @click="onSubmitReward">
          Submit
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import JackpotService from "@/services/app/jackpot.service";
import moment from "moment";
import {notification} from "ant-design-vue";
import {convertGmt9, convertGmt9FromUtc} from "../../helpers/time";

export default {
  name: 'Jackpot',
  components: {
    UsersTable: () => import('@/components/Pages/Users/UsersTable')
  },
  data() {
    return {
      moment,
      breadcrumb: [
        {
          path: '/jackpot',
          label: 'List jackpot'
        }
      ],
      query: {
        currentPage: 1,
        pageSize: 10,
        time: moment(new Date(), 'DD-MM-YYYY')
      },
      timeout: null,
      listJackpots: [],
      total: 0,
      loading: false,
      columns: [
        {
          title: 'Order',
          width: 80,
          key: 'stt',
          scopedSlots: {customRender: 'stt'}
        },
        {
          title: 'Benefit',
          key: 'benefit',
          scopedSlots: {customRender: 'benefit'}
        },
        {
          title: 'Status',
          scopedSlots: { customRender: 'status' },
          width: 160,
          key: 'status',
        },
        {
          title: 'Start time',
          width: 220,
          key: 'startTime',
          scopedSlots: { customRender: 'startTime' },
        },
        {
          title: 'End time',
          width: 220,
          key: 'endTime',
          scopedSlots: { customRender: 'endTime' },
        },
        {
          title: 'Action',
          width: 120,
          key: 'action',
          scopedSlots: {customRender: 'action'},
          fixed: 'right',
          align: 'center'
        },
      ],
      columnsUsers: [
        {
          title: 'Wallet address',
          scopedSlots: {customRender: 'walletAddress'},
          ellipsis: true,
          key: 'walletAddress',
        },
        {
          title: 'Status',
          scopedSlots: {customRender: 'status'},
          width: 120,
          key: 'status',
        },
        {
          title: 'Tickets',
          width: 120,
          dataIndex: 'ticket',
          key: 'ticket',
        },
        {
          title: 'Action',
          width: 120,
          key: 'action',
          scopedSlots: {customRender: 'action'},
          fixed: 'right',
          align: 'center'
        },
      ],
      visible: false,
      selectedJackpot: null,
      rewardAmount: null,
      rewardRank: 1,
      listUsers: [],
      loadingUsers: false,
      queryUsers: {
        page: 0,
        size: 10,
      },
      totalUsers: 0,
      loadingSubmitReward: false,
      selectedWalletAddress: '',
      rankOptions: [1, 2, 3, 4, 5].map(item => ({label: `Rank ${item}`, value: item}))
    }
  },
  created() {
    this.setBreadcrumb(this.breadcrumb)
  },
  mounted() {
    this.onFetchData()
  },

  methods: {
    convertGmt9,
    convertGmt9FromUtc,
    async onFetchData() {
      try {
        this.loading = true
        const payload = {
          ...this.query,
          time: moment(this.query.time).format('DD-MM-YYYY HH:mm:ss')
        }
        const response = await JackpotService.getList(payload)
        if (response.code === 1) {
          this.listJackpots = response.data || []
          this.total = response.totalElement || 0
        }
      } catch (e) {
        notification.error({
          message: e.message
        })
      } finally {
        this.loading = false
      }
    },
    async onFetchDataUsers() {
      if (!this.selectedJackpot) return
      this.loadingUsers = true
      const params = {
        ...this.queryUsers,
        rank: this.rewardRank
      }
      const response = await JackpotService.getUsersByJackpot(this.selectedJackpot.id, params)
      if (response.code === 1) {
        this.listUsers = response.data || []
        this.totalUsers = response.totalElement || 0
      }
      this.loadingUsers = false
    },
    onChangeFilter() {
      this.query.currentPage = 1
      this.onFetchData();
    },
    onChangeTable(val) {
      this.query.currentPage = val.current
      this.onFetchData()
    },
    onChangeTableUsers(val) {
      this.queryUsers.page = val.current
      this.onFetchDataUsers()
    },
    async onShowModalReward(item) {
      this.visible = true
      this.selectedJackpot = {...item}
      await this.onFetchDataUsers()
    },
    onCloseModalReward() {
      this.selectedJackpot = null
      this.rewardAmount = null
      this.rewardRank = 1
      this.selectedWalletAddress = ''
      this.queryUsers.page = 1
      this.visible = false
    },
    onRadioChange(event) {
      this.selectedWalletAddress = event.target.value
    },
    onChangeRank() {
      this.selectedWalletAddress = ''
      this.onFetchDataUsers()
    },
    async onSubmitReward() {
      try {
        const payload = {
          jackpotId: this.selectedJackpot.id,
          userWalletAddress: this.selectedWalletAddress,
          rank: this.rewardRank,
          amount: this.rewardAmount
        }
        this.loadingSubmitReward = true
        const response = await JackpotService.createReward(payload)
        if (response.code === 1) {
          this.onFetchData()
          notification.success({
            message: 'Reward success'
          })
          this.onCloseModalReward()
        } else {
          notification.error({
            message: response.message
          })
        }
      } catch (e) {
        notification.error({
          message: e.message
        })
      } finally {
        this.loadingSubmitReward = false
      }
    }
  }
}
</script>

<style scoped>
.jackpot-page {
  padding: 24px;
}
</style>
