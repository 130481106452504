import moment from "moment";

export const convertGmt7 = (input, inputFormat, outputFormat) => {
  if (!input) return ''
  return moment(input, inputFormat).add(7, 'hours').format(outputFormat)
}

export const convertGmt9 = (input, inputFormat, outputFormat) => {
  if (!input) return ''
  return moment(input, inputFormat).add(9, 'hours').format(outputFormat)
}

export const convertGmt9FromUtc = (input, outputFormat) => {
  if (!input) return ''
  return moment.utc(input).add(9, 'hours').format(outputFormat)
}